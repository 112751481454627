<template>
  <section class="section section-trips" :class="{ agent: isAgent }">
    <div class="container">
      <div class="headline">
        <h2 class="headline-title fw-900" v-html='headlineTitle'></h2>
        <div class="headline-description">
          <p class="mb-0" v-html='subTitle'></p>
        </div>
<!--        <div class="headline-small">
          <span class="gray-dark">*Terms and conditions apply.</span>
        </div>-->
      </div>
      <div class="inner-wrapper">
        <div
          class="flex-wrapper"
          v-if="galleryData && galleryData.length !== 0"
        >
          <div v-for="(item, i) in galleryData" :key="i" style="width: 290px">
            <TripCard :cardData="item" />
          </div>
        </div>
      </div>
      <div class='tc flex mt-40 mb-mt-20 justify-center'>
        <router-link
          to='/trip-gallery'
          title='View all trips' aria-label='View all trips'
          class='button-border button-gray-dark ttc py-12 px-20 fs-14 lh-16 br-14 fw-700 h-40'
        >
          Explore our Tour Gallery
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import TripCard from '~/components/elements/TripCard/TripCard'
import { computed, ref } from 'vue'
import tripApi from '~/composables/api/tripApi'
import { publicDomain } from '~/helper'
import {useCurrenciesStore} from '~/store/pinia/currencies'

export default {
  props: ['isAgent'],
  components: {
    TripCard,
  },
  async setup() {
    const galleryData = ref([])
    const domain = publicDomain()
    const currenciesStore = useCurrenciesStore()

    const selectedCurrency = computed(() => {
      // return store.getters['currenciesStore/getCurrencySelected']
      return currenciesStore.getCurrencySelected
    })

    const getDataTrips = async () => {
      try {
        const res = await tripApi.getListTrip({
          limit: 4,
          is_free_cancellation: false,
          currency: selectedCurrency.value?.code,
          sort: 'popular',
          page: 1,
          price: 0
        })
        galleryData.value = res.data.value?.data?.items || []
      } catch (e) {
        console.log(e.message)
        throw new Error('Get trip data error (homepage) : ', { cause: e })
      }
    }

    const headlineTitle = computed(() => {
      return domain === 'agent' ? 'Fully Branded Trips' : 'Get Inspired by our Gallery'
    })

    const subTitle = computed(() => {
      return domain === 'agent' ? `2,000+ itineraries fully branded under your branding. <br/>Let us know how you want to tailor these trips to your clients’ specific needs.` : `Immersing yourself in our vast gallery of 2,000+ itineraries. <br/>Then, share your travel preferences, we’ll craft a tailored trip designed exclusively for you.`
    })

    // onMounted(async () => {
    //   await getDataTrips()
    // })
    await getDataTrips()

    return {
      galleryData, headlineTitle, subTitle
    }
  },
}
</script>
<style scoped lang="scss">
@import './SectionStyle.scss';
</style>
